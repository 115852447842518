// extracted by mini-css-extract-plugin
export var BagIcon = "index-module--BagIcon--76271";
export var CheckoutSummary = "index-module--CheckoutSummary--d9fc7";
export var CheckoutSummaryLabel = "index-module--CheckoutSummaryLabel--34623";
export var Content = "index-module--Content--d8fb2";
export var CreditCardIcon = "index-module--CreditCardIcon--e120d";
export var DesktopPadding = "index-module--DesktopPadding--ee428";
export var ErrorContent = "index-module--ErrorContent--84039";
export var ErrorSecondaryContent = "index-module--ErrorSecondaryContent--7fc0b";
export var IconComponent = "index-module--IconComponent--d8ca3";
export var Loader = "index-module--Loader--3ad7f";
export var LocationLabel = "index-module--LocationLabel--30b8a";
export var OrderSummary = "index-module--OrderSummary--1a2ea";
export var PaymentFooterDesktop = "index-module--PaymentFooterDesktop--18a8b";
export var PaymentFooterMobile = "index-module--PaymentFooterMobile--f5872";
export var PaymentSuccessContent = "index-module--PaymentSuccessContent--211ba";
export var PaymentTypeButton = "index-module--PaymentTypeButton--e35f9";
export var ResponsiveWrapper = "index-module--ResponsiveWrapper--ec891";
export var SVGWrapper = "index-module--SVGWrapper--00937";
export var SavingOrder = "index-module--SavingOrder--60443";
export var SecondaryContent = "index-module--SecondaryContent--8b9a9";
export var ShowInDesktop = "index-module--ShowInDesktop--8811b";
export var fadeAndGrowInDown = "index-module--fadeAndGrowInDown--7bc6d";
export var fadeAndGrowInUp = "index-module--fadeAndGrowInUp--bcb70";
export var paymentMethodEnter = "index-module--paymentMethodEnter--e61c6";
export var paymentMethodEnterActive = "index-module--paymentMethodEnterActive--0bd85";
export var paymentMethodLeave = "index-module--paymentMethodLeave--8e1cf";
export var paymentMethodLeaveActive = "index-module--paymentMethodLeaveActive--ca843";