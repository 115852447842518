import React from "react";
import findIndex from "lodash/findIndex";

export default class InlineSelect extends React.Component {
  // not handling limit
  constructor(props) {
    super(props);

    const {
      selectedIndex: selectedIndexFromProps,
      limit,
      value,
      options,
    } = props;
    if (selectedIndexFromProps && typeof value !== "undefined") {
      console.warn(
        "Inline Select has both selectedIndex and value props in the same time, using selected index",
      );
    }
    const selectedValueIndex = findIndex(options, { value });
    const selectedIndex = selectedIndexFromProps
      ? selectedIndexFromProps
      : selectedValueIndex;
    this.state = { selectedIndex };
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  // not handling limit
  componentWillReceiveProps(nextProps) {
    const { selectedIndex } = nextProps;

    if (typeof selectedIndex === "number") {
      this.setState({ selectedIndex: selectedIndex });
    }
  }

  onChange = selectedIndex => {
    const { onChange, children, options } = this.props;
    const selectedOption = options[selectedIndex];
    const selectedValue = selectedOption ? selectedOption.value : null;
    this.setState({ selectedIndex }, () => {
      onChange && onChange(selectedValue);
    });
  };

  renderOption = (option, index, checked, last, rtl) => {
    const primaryColor = this.props.selectedColor;

    const style = {
      display: "flex",
      flexGrow: 1,
      paddingTop: 6,
      paddingBottom: 6,
      alignItems: "center",
      justifyContent: "center",
      ...(rtl
        ? { borderLeft: last ? "none" : "1px solid" }
        : { borderRight: last ? "none" : "1px solid" }),
      borderColor: primaryColor,
      color: checked ? "white" : primaryColor,
      background: checked ? primaryColor : "transparent",
    };

    return (
      <div key={index} style={style} onClick={() => this.onChange(index)}>
        {option.label}
      </div>
    );
  };

  render() {
    const { selectedIndex } = this.state;
    const {
      options,
      selectedIndex: selectedIndexProp,
      limit,
      children,
      title,
      selectedColor,
      rtl,
      ...props
    } = this.props;

    const primaryColor = selectedColor;

    const style = {
      display: "inline-flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 32,
    };
    const titleBaseStyle = {
      display: "flex",
      flexGrow: 1,
      fontSize: "0.875rem",
    };
    const titleStyle = {
      ...titleBaseStyle,
      fontWeight: "bold",
      color: primaryColor,
    };

    const optionContainerStyle = {
      display: "flex",
      flexGrow: 1,
      border: "1px solid",
      borderColor: primaryColor,
      marginBottom: 16,
      marginTop: title ? 0 : 10,
    };

    return (
      <div>
        {title && (
          <div style={style} {...props} className="noselect">
            <span style={titleStyle}>{title}</span>
          </div>
        )}
        {options && (
          <div style={optionContainerStyle}>
            {options.map((option, index) =>
              this.renderOption(
                option,
                index,
                selectedIndex === index,
                index + 1 === options.length,
                rtl,
              ),
            )}
          </div>
        )}
      </div>
    );
  }
}
