import {get, toLower} from "lodash";
import React from "react";
import Modal from "../Modal";
import Button from "../Button";
import CarryoutIcon from "../icons/Carryout.svg";
import PizzaCarryoutIcon from "../icons/PizzaCarryout.svg";
import SitdownIcon from "../icons/Sitdown.svg";
import ConfirmModalHeaderIcon from "../icons/ConfirmModalHeader.svg";
import * as styles from "./index.module.scss";
import {navigateTo} from "../../utils/location";


export default ({
                  appStyles,
                  T,
                  onConfirm,
                  show,
                  onClose,
                  servingOption,
                  branch,
                }) => {
  const servingOptionIcons = {
    pickup: appStyles.isPizza ? <PizzaCarryoutIcon/> : <CarryoutIcon/>,
    sitdown: <SitdownIcon/>,
  };

  const confirmModalHeaderIcon = appStyles.placeOrderModalChangeLocationButtonIsVisible ? <ConfirmModalHeaderIcon/> : servingOptionIcons[servingOption.type];
  const servingOptionTag = toLower(servingOption.servingOptionTag);

  function onChangeLocation() {
    navigateTo("/find-location");
    onClose();
  }
  return (
    <Modal
      open={show}
      onClose={onClose}
      focusTrapped
      appStyles={appStyles}
    >
      <div
        style={{color: appStyles.actionColor}}
        className={styles.SVGWrapper}
      >
        {confirmModalHeaderIcon}
      </div>
      <div style={{textAlign: "center"}} role="heading" aria-level="1">
        {T(
          `${T(`${T(`You are ordering`)} ${servingOptionTag} ${T("from")}`)}`
        )}
        <div>
          <h2 style={{...appStyles.CardTitle, textAlign: "center", marginBottom: "0.25em"}}>
            {branch.name}
          </h2>
          <div>
            {branch.address}
          </div>
        </div>
      </div>
      <div
        style={{
          ...appStyles.StartNewOrderPopupTitle,
          textAlign: "center",
          margin: "16px 0",
          color: appStyles.accentColor,
        }}
        role="heading"
        aria-level="2"
      >
        <strong>
          {T("Please be sure to be on time to collect your order")}
        </strong>
      </div>
      <div>
        <Button
          appStyles={appStyles}
          centered
          style={{marginBottom: 15}}
          onClick={onConfirm}
        >
          {T("Confirm & Place Order")}
        </Button>
        {
          (!servingOption.needsAddress && appStyles.placeOrderModalChangeLocationButtonIsVisible) && (
            <Button
              appStyles={appStyles}
              centered
              style={{marginBottom: 15, background: appStyles.placeOrderModalChangeLocationButtonBackgroundColor }}
              onClick={onChangeLocation}
            >
              {T(`Change ${servingOptionTag} Location`)}
            </Button>
          )
        }
        <Button
          linkStyle
          appStyles={appStyles}
          style={{paddingBottom: 10, paddingTop: 10}}
          centered
          onClick={onClose}
        >
          {T("Close")}
        </Button>
      </div>
    </Modal>
  );
};
