import React from "react";
import Card from "../Card";
import { FullNameInput, PhoneInput, EmailInput, InputGroup } from "../Inputs";
import { PAYMENT } from "../../utils/constants";

export default ({
  title,
  appStyles,
  T,
  hasErrors,
  animateError,
  refEl,
  areaCode,
  inputProps,
}) => (
  <Card
    appStyles={appStyles}
    hasErrors={hasErrors}
    animateError={animateError}
    refEl={refEl}
  >
    <Card.Title light appStyles={appStyles}>
      {title}
    </Card.Title>
    <Card.Content>
      <InputGroup appStyles={appStyles} T={T}>
        <FullNameInput
          {...inputProps[PAYMENT.NAME]}
          placeholder={T("Full Name")}
        />
        <PhoneInput
          {...inputProps[PAYMENT.PHONE_NUMBER]}
          placeholder={T("Phone Number")}
          areaCode={areaCode}
          locale={appStyles.locale}
        />
        <EmailInput
          {...inputProps[PAYMENT.EMAIL]}
          notRequired={appStyles.noEmailRequired}
          placeholder={T("Email Address")}
        />
      </InputGroup>
    </Card.Content>
  </Card>
);
